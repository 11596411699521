<template>
  <v-container id="Dashboard-view" fluid tag="section" fill-height class="px-1">
<picture-input
  ref="pictureInput"
  :width="500"
  :removable="true"
  removeButtonClass="ui red button"
  :height="500"
  accept="image/jpeg, image/png, image/gif"
  buttonClass="ui button primary"
  :customStrings="{
  upload: '<h1>Upload it!</h1>',
  drag: 'Drag and drop your image here'}">

</picture-input>    <v-row>
      <v-col cols="12" md="6">
        <v-card
          elevation="15"
          class="mx-auto px-3 mb-2 pb-3"
          :min-height="$vuetify.breakpoint.mdAndUp ? '700px' : '20vh'"
        >
          <v-row>
            <v-col cols="12">
              <v-select
                :items="branches"
                item-text="name"
                item-value="branchID"
                hide-details="auto"
                v-model="branchId"
                :loading="loadingBranch"
                label="Branch"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                name="LRNo"
                label="Consignment No"
                v-model="lrNo"
                id="LRNo"
                @blur="lostFocusLrNo"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="lrDates"
                item-text="consignmentDate"
                item-value="consignmentID"
                :loading="loadingLRDate"
                v-model="lrId"
                label="Consignment Date"
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" :loading="loadingData" @click="loadData"
                >Show POD</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          elevation="15"
          class="mx-auto px-3 mb-2 pb-3"
          width="100%"
          :min-height="$vuetify.breakpoint.mdAndUp ? '700px' : '20vh'"
        >
          <v-row>
            <v-col cols="12">
              <v-tabs
                v-model="tab"
                background-color="deep-purple accent-4"
                centered
                dark
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                  Frontside
                </v-tab>

                <v-tab>
                  Backside
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card flat>
                    <v-img
                      v-if="podFront.fileName"
                      contain
                      height="500px"
                      :src="apiUrl + 'Upload/' + podFront.fileName"
                      alt="No Image"
                    >
                    </v-img>

                    <v-card-text>
                      <v-file-input
                        accept="image/*"
                        capture
                        placeholder="Please select Image file"
                        prepend-icon="mdi-image"
                        label="Image File"
                        show-size
                        @change="loadImageFront"
                        :loading="importLoading"
                        v-if="isUser && isEdit"
                      ></v-file-input>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-img
                      v-if="podBack.fileName"
                      contain
                      height="500px"
                      :src="apiUrl + 'Upload/' + podBack.fileName"
                      alt="No Image"
                    >
                    </v-img>
                    <v-card-text>
                      <v-file-input
                        accept="image/*"
                        capture
                        placeholder="Please select Image file"
                        prepend-icon="mdi-image"
                        label="Image File"
                        @change="loadImageBack"
                        :loading="importLoading"
                        show-size
                        v-if="isUser"
                      ></v-file-input>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped></style>
<script>
import axios from "axios";
import { get } from "vuex-pathify";
import { showSnackbar } from "../util/globalActions";
export default {
  name: "UploadPODView",
  data: () => ({
    image: null,
    name: "Upload POD",
    isUser: false,
    isEdit: false,
    tab: null,
    lrNo: "",
    lrId: "",
    lrDates: [],
    branches: [],
    branchId: "",
    loadingData: false,
    loadingBranch: false,
    loadingLRDate: false,
    importLoading: false,
    podFront: {
      documentAttachmentID: "",
      documentID: "",
      documentType: "",
      fileName: "",
      orderNo: "",
      orignalFileName: "",
    },
    podBack: {
      documentAttachmentID: "",
      documentID: "",
      documentType: "",
      fileName: "",
      orderNo: "",
      orignalFileName: "",
    },
  }),
  created() {
    const self = this;
    self.loadBranches();
    self.checkRole();
  },
  watch: {
    branchId: function(val) {
      const self = this;
      self.loadLRDates(val, self.lrNo);
    },
  },
  computed: {
    apiUrl: get("user/apiUrl"),
    userName: get("user/userName"),
  },

  methods: {
    lostFocusLrNo() {
      this.loadLRDates(this.branchId, this.lrNo);
    },
    loadLRDates(branchid, lrno) {
      const self = this;
      self.podFront = {
        documentAttachmentID: "",
        documentID: "",
        documentType: "",
        fileName: "",
        orderNo: "",
        orignalFileName: "",
      };
      self.podBack = {
        documentAttachmentID: "",
        documentID: "",
        documentType: "",
        fileName: "",
        orderNo: "",
        orignalFileName: "",
      };

      if (branchid === "" || lrno === "") {
        self.lrDates = [];
        return;
      }

      self.loadingLRDate = true;
      self.lrDates = [];
      self.lrId = "";

      axios({
        url:
          self.apiUrl + "api/Consignment/ofNobranch/" + branchid + "/" + lrno,
        method: "GET",
      })
        .then(function(res) {
          self.lrDates = res.data;
          self.loadingLRDate = false;
        })
        .catch(function(err) {
          self.loadingLRDate = false;
          console.log(err.status);
        });
    },
    loadBranches() {
      const self = this;
      self.loadingBranch = true;

      axios({
        url: self.apiUrl + "api/Branches",
        method: "GET",
      })
        .then(function(res) {
          self.branches = res.data;
          self.branchId = res.data[0].branchID;
          self.loadingBranch = false;
        })
        .catch(function(err) {
          self.loadingBranch = false;
          console.log(err.status);
        });
    },
    loadData() {
      const self = this;
      self.loadingData = true;
      axios({
        url: self.apiUrl + "api/Consignment/ofId/" + self.lrId,
        method: "GET",
      })
        .then(function(res) {
          self.podFront = {
            documentAttachmentID: "",
            documentID: "",
            documentType: "",
            fileName: "",
            orderNo: "",
            orignalFileName: "",
          };
          self.podBack = {
            documentAttachmentID: "",
            documentID: "",
            documentType: "",
            fileName: "",
            orderNo: "",
            orignalFileName: "",
          };
          let isFound = false;
          res.data.forEach((element) => {
            element.orignalFileName = "";
            isFound = true;
            if (element.documentType === "LR Frontside") {
              self.podFront = element;
            } else {
              self.podBack = element;
            }
          });

          if (isFound && self.userName.toUpperCase() != "SSNRL")
          {
            self.isEdit = false;
          }
          else
            self.isEdit = true;

          self.loadingData = false;
        })
        .catch(function(err) {
          self.loadingData = false;
          console.log(err.status);
        });
    },
    loadImageFront(files) {
      this.loadImage(files, "LR Frontside");
    },
    loadImageBack(files) {
      this.loadImage(files, "LR Backside");
    },
    loadImage(files, documentType) {
      if (files) {
        this.importLoading = true;
        let formData = new FormData();

        formData.append("files", files, files.name);
        // additional data
        formData.append("documentID", this.lrId);
        formData.append("documentType", documentType);
        if (documentType == "LR Frontside") {
          formData.append(
            "documentAttachmentID",
            this.podFront.documentAttachmentID
          );
          formData.append("fileName", this.podFront.fileName);
        } else {
          formData.append(
            "documentAttachmentID",
            this.podBack.documentAttachmentID
          );
          formData.append("fileName", this.podBack.fileName);
        }
        axios
          .post(this.apiUrl + "api/Consignment/upload", formData)
          .then((response) => {
            this.importLoading = false;
            if (response.data.status === "OK") {
              response.data.data.orignalFileName = "";
              if (documentType == "LR Frontside")
                this.podFront = response.data.data;
              else this.podBack = response.data.data;
              showSnackbar({
                message: "File uploaded successfully",
                color: "success",
              });
              this.dialogImport = false;
            } else {
              showSnackbar({ message: "File uploaded error" });
            }
          })
          .catch((error) => {
            showSnackbar({ message: "File Imported Error!" });
            console.log(error);
            this.importLoading = false;
          });
      } else {
        console.log("there are no files.");
      }
    },
    checkRole() {
      const self = this;

      axios({
        url: self.apiUrl + "api/Users/getmyrole",
        method: "GET",
      })
        .then(function(res) {
          console.log(res.data);
          if (res.data === "Party") self.isUser = false;
          else self.isUser = true;
        })
        .catch(function(err) {
          console.log(err.status);
        });
    },
  },
};
</script>
