<template>
  <v-container id="Dashboard-view" fluid tag="section" class="px-0 py-0">
    <v-card>
      <v-toolbar color="#000080" dark>
       
        <v-img  :src="logoUrl"  max-width="45"></v-img>
        
        <v-toolbar-title class="amber--text pl-5">{{ companyName }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu
          bottom
          left
          min-width="200"
          offset-y
          origin="top right"
          transition="scale-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list :tile="false" flat nav>
            <v-list-item>
              <v-list-item-title>{{ fullName }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list :tile="false" flat nav>
            <template v-for="(p, i) in profile">
              <v-divider
                v-if="p.divider"
                :key="`divider-${i}`"
                class="mb-2 mt-2"
              />
              <v-list-item
                v-else
                :key="`item-${i}`"
                @click="menuClick(`item-${i}`)"
              >
                <v-list-item-title>{{ p.title }}</v-list-item-title>
                <v-icon>{{ p.icon }}</v-icon>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title icons-and-text fixed-tabs>
            <v-tabs-slider color="pink" ></v-tabs-slider>

            <v-tab class="amber--text">
              LR Status
              <v-icon>mdi-list-status</v-icon>
            </v-tab>
            <v-tab class="amber--text">
              POD
              <v-icon>mdi-image</v-icon>
            </v-tab>
            <v-tab class="amber--text">
              MultiModal
              <v-icon>mdi-airplane</v-icon>
            </v-tab>
            <v-tab class="amber--text">
              Contact Us
              <v-icon>mdi-phone</v-icon>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab" class="py-3">
        <v-tab-item>
          <CheckStatus></CheckStatus>
        </v-tab-item>
        <v-tab-item>
          <UploadPOD></UploadPOD>
        </v-tab-item>
        <v-tab-item>
          <MultiModal></MultiModal>
        </v-tab-item>
        <v-tab-item>
          <ContactUs></ContactUs>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<style>
.theme--light.v-label {
    color: #000080;
}

.theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: #000080;
}
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;

}


.v-tab {
  font-family: "Museo", Helvetica, Arial !important;
  letter-spacing: .0001em !important;
  font-size: .6rem !important;
  padding: 0 0px !important;
  margin: 0 0px !important;
  min-width: 80px !important;
  
}

</style>
<script>
import CheckStatus from "./CheckStatus.vue";
import UploadPOD from "./UploadPOD.vue";
import ContactUs from "./ContactUs.vue";
import MultiModal from "./MultiModal.vue";
import { get } from "vuex-pathify";
export default {
  components: { UploadPOD, CheckStatus, ContactUs, MultiModal },
  name: "DashboardView",
  data: () => ({
    name: "Dashboard",
    tab: null,
    profile: [
      { divider: true },
      { title: "Log out", icon: "mdi-logout" },
    ],
  }),
  computed: {
    ...get("user", ["isLogin", "fullName", "companyName","logoUrl"]),
    
  },
  methods: {
    uploadPOD() {
      this.$router.push({ name: "Upload POD" });
    },
    checkStatus() {
      this.$router.push({ name: "Check Status" });
    },
    menuClick(menuKey) {
      if (menuKey === "item-1") {
        this.$store.dispatch("user/logout").then(() => {
          this.$router.push({ name: "Login" });
        });
      }
    },
  },
};
</script>
