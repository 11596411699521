// Utilities
//import { make } from 'vuex-pathify'
// Globals
import { IN_BROWSER, AUTH_SUCCESS, AUTH_LOGOUT } from '@/util/globals'

const state = {
  isLogin: localStorage.getItem("user") || false,
  token: "",
  fullName:"",
  userName:"",
  id:"",
  expiration:"",
  apiUrl:"",
  companyName:"",
  logoUrl:"",
  
  
}

const mutations = {
  [AUTH_SUCCESS]: (state, resp) => {
    state.token = resp.token;
    state.userName = resp.userName;
    state.id = resp.id;
    state.fullName = resp.fullName;
    state.expiration = resp.expiration;
    state.isLogin = true;
    state.apiUrl = resp.apiUrl;
    state.companyName = resp.companyName;
    state.logoUrl = resp.logoUrl;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
    state.userName = "";
    state.id = "";
    state.fullName = "";
    state.expiration = "";
    state.isLogin = false;
    state.apiUrl = "";
    state.companyName = "";
    state.logoUrl = "";
  },
}

const actions = {
  fetch: ({ commit }) => {
    const local = localStorage.getItem('user') || '{}'
    const user = JSON.parse(local)
    commit(AUTH_SUCCESS,user);
  },
  login: ({  commit },data) => {
    if (!IN_BROWSER) return

    localStorage.setItem('user', JSON.stringify(data));
    
    commit(AUTH_SUCCESS,data);
    
    //commit("isLogin",true);

  },
  logout: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem("user");
      resolve();
    });
  }
}

const getters = {
  token: (state) => {
    return (
      state.token
    )
  },
  userName: state => {
    return state.userName
  },
  fullName: state => {
    return state.fullName
  },
  apiUrl: state => {
    return state.apiUrl
  },  
  companyName: state => {
    return state.companyName
  },  
  logoUrl: state => {
    return state.logoUrl
  }, 
  isLogin: state => !!state.token,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
