<template>
  <v-container id="Dashboard-view" fluid tag="section" class="px-1">
    <v-row>
      <v-col cols="12" md="6">
        <v-card
          elevation="15"
          class="mx-auto px-3 mb-2 pb-3"
          :min-height="$vuetify.breakpoint.mdAndUp ? '700px' : '20vh'"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                name="LRNo"
                label="Consignment No"
                v-model="lrNo"
                hide-details="auto"
                id="LRNo"
                @blur="loadLRDates"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="lrDates"
                item-text="consignmentDate"
                item-value="consignmentID"
                :loading="loadingLRDate"
                v-model="lrId"
                label="Consignment Date"
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" :loading="loadingData" @click="loadData"
                >Show Status</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          elevation="15"
          class="mx-auto px-3 mb-2 pb-3"
          :min-height="$vuetify.breakpoint.mdAndUp ? '700px' : '20vh'"
        >
          <v-btn
            class="mt-8"
            absolute
            top
            color="pink"
            right
            fab
            v-if="listingData.length > 0"
            :loading="importLoading"
            @click="loadPOD"
          >
            <v-icon>mdi-image</v-icon>
          </v-btn>
          <v-row>
            <v-col>
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(item, index) in listingData"
                  :color="item.color"
                  :icon="item.icon"
                  :key="index"
                >
                  <v-row dense class="pt-1 nblue">
                    <v-col cols="4">
                      <strong>{{ item.documentDate }}</strong>
                    </v-col>
                    <v-col>
                      <strong>{{ item.documentNumber }}</strong>
                      <div class="text-caption">
                        {{ item.description }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>POD</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-tabs
          v-model="tab"
          background-color="deep-purple accent-4"
          centered
          dark
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab>
            Frontside
          </v-tab>

          <v-tab>
            Backside
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-row>
                <v-col cols="12">
                  <v-img
                    v-if="podFront.fileName"
                    contain
                    height="500px"
                    :src="apiUrl + 'Upload/' + podFront.fileName"
                    alt="No Image"
                  >
                  </v-img>
                </v-col>
                <v-col cols="12" align="center">
                  <v-btn color="primary" fab @click="download(podFront.fileName)"
                    ><v-icon>mdi-download</v-icon></v-btn
                  >
                  <v-btn v-if="webShareApiSupported"
                    color="primary" fab
                    @click="shareImage(apiUrl + 'Upload/' + podFront.fileName)"
                    ><v-icon>mdi-share-variant</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-row>
                <v-col cols="12">
                  <v-img
                    v-if="podBack.fileName"
                    contain
                    height="500px"
                    :src="apiUrl + 'Upload/' + podBack.fileName"
                    alt="No Image"
                  >
                  </v-img>
                </v-col>
                <v-col cols="12" align="center">
                  <v-btn color="primary" fab @click="download(podBack.fileName)"
                    ><v-icon>mdi-download</v-icon></v-btn
                  >
                  <v-btn v-if="webShareApiSupported"
                    color="primary" fab
                    @click="shareImage(apiUrl + 'Upload/' + podBack.fileName)"
                    ><v-icon>mdi-share-variant</v-icon></v-btn>

                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
.nblue {
  color: #000080;
}
</style>
<script>
import axios from "axios";
import { get } from "vuex-pathify";
import { showSnackbar } from "../util/globalActions";

export default {
  name: "CheckStatusView",
  data: () => ({
    name: "Check Status",
    lrNo: "",
    lrId: "",
    tab: null,
    dialog: false,
    lrDates: [],
    loadingData: false,
    loadingLRDate: false,
    importLoading: false,
    podFront: {
      documentAttachmentID: "",
      documentID: "",
      documentType: "",
      fileName: "",
      orderNo: "",
      orignalFileName: "",
    },
    podBack: {
      documentAttachmentID: "",
      documentID: "",
      documentType: "",
      fileName: "",
      orderNo: "",
      orignalFileName: "",
    },
    listingData: [],
    podData: [],
  }),
  created() {},
  watch: {},

  computed: {
    apiUrl: get("user/apiUrl"),
    webShareApiSupported() {
      return navigator.share;
    },
  },

  methods: {
    loadLRDates() {
      const self = this;
      self.listingData = [];
      if (self.lrNo === "") {
        self.lrDates = [];
        return;
      }

      self.loadingLRDate = true;
      self.lrDates = [];
      self.lrId = "";

      axios({
        url: self.apiUrl + "api/Consignment/ofNo/" + self.lrNo,
        method: "GET",
      })
        .then(function(res) {
          self.lrDates = res.data;
          self.loadingLRDate = false;
        })
        .catch(function(err) {
          self.loadingLRDate = false;
          console.log(err.status);
        });
    },
    loadPOD() {
      const self = this;
      self.importLoading = true;
      axios({
        url: self.apiUrl + "api/Consignment/ofId/" + self.lrId,
        method: "GET",
      })
        .then(function(res) {
          self.podFront = {
            documentAttachmentID: "",
            documentID: "",
            documentType: "",
            fileName: "",
            orderNo: "",
            orignalFileName: "",
          };
          self.podBack = {
            documentAttachmentID: "",
            documentID: "",
            documentType: "",
            fileName: "",
            orderNo: "",
            orignalFileName: "",
          };
          console.log(res.data);
          res.data.forEach((element) => {
            element.orignalFileName = "";
            if (element.documentType === "LR Frontside") {
              self.podFront = element;
            } else {
              self.podBack = element;
            }
          });
          self.dialog = true;
          self.importLoading = false;
        })
        .catch(function(err) {
          self.importLoading = false;
          console.log(err.status);
        });
    },
    loadData() {
      const self = this;
      self.loadingData = true;
      axios({
        url: self.apiUrl + "api/Consignment/getLRStatus/" + self.lrId,
        method: "GET",
      })
        .then(function(res) {
          self.listingData = res.data;
          self.loadingData = false;
        })
        .catch(function(err) {
          self.loadingData = false;
          showSnackbar({ message: "Data not found!" });
          console.log(err.status);
        });
    },
    download(imagelink) {
      const data = { fileName: imagelink };
      axios({
        url: this.apiUrl + "api/Consignment/getPOD",
        data: data,
        method: "POST",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", imagelink);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    shareImage(fileName) {
      navigator.share({
        title: "Title to be shared",
        text: "Text to be shared",
        url: fileName,
      });
    },
  },
};
</script>
