// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import DashboardView from '../views/Dashboard.vue'
import LoginView from '../views/Login.vue'
import UploadPOD from '../views/UploadPOD.vue'
import CheckStatus from '../views/CheckStatus.vue'
import ChangePassword from '../views/ChangePassword.vue'
import ContactUs from '../views/ContactUs.vue'

Vue.use(Router)

const router = new Router({
  root: '/',
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: DashboardView,
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView,
    },
    {
      path: '/uploadpod',
      name: 'Upload POD',
      component: UploadPOD,
    },
    {
      path: '/checkStatus',
      name: 'Check Status',
      component: CheckStatus,
    },
    {
      path: '/changepassword',
      name: 'Change Password',
      component: ChangePassword,
    },    
    {
      path: '/contactus',
      name: 'Contact Us',
      component: ContactUs,
    },    
  ],
})

router.beforeEach((to, from, next) => {
  const user = store.getters['user/isLogin']
  
  if (to.path != '/login')
  {
    if (user) {
      next();
      return;
    }
    next("/login");
  }
  else
  {
    if (!user) {
      next();
      return;
    }
    next("/");
  }
  
})

export default router
