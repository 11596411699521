<template>
  <v-app>
    <v-main class="mx-0 px-0">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
      <v-snackbar
        v-model="snackbar"
        :color="snackbarColor"
        right
        top
        timeout="2500"
        >{{ snackbarMessage }}</v-snackbar
      >
    </v-main>
  </v-app>
</template>
<style>


@font-face {
  font-family: "Verdana";
  src: local("Verdana"), url(./fonts/VERDANA.TTF) format("truetype");
}
</style>
<script>
import EventBus, { ACTIONS } from "./util/eventbus";
export default {
  name: "App",
metaInfo: {
    title: "SSNRL Pocket",
    titleTemplate: "SSNRL Pocket",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },  
  data: () => ({
    snackbar: false,
    snackbarMessage: "",
    snackbarColor: "red",
    //
  }),
  mounted() {
    EventBus.$on(ACTIONS.SNACKBAR, (message) => {
      this.snackbarMessage = message.message;
      console.log(message.color);
      if (message.color != undefined) this.snackbarColor = message.color;
      else this.snackbarColor = "error";
      this.snackbar = true;
    });
  },
};
</script>
