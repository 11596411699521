// Vue
import Vue from 'vue'
import Vuex from 'vuex'
import pathify from '@/plugins/vuex-pathify'
import user from "./modules/user";
import app from "./modules/app";

// Modules
//import * as modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user
  },
  plugins: [
    pathify.plugin,
  ],
})
/*
store.subscribe(mutation => {
  if (!mutation.type.startsWith('user/')) return

  store.dispatch('user/login', mutation)
})
*/
store.dispatch('user/fetch');

export default store

export const ROOT_DISPATCH = Object.freeze({ root: true })
