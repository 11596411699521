import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import axios from 'axios';

Vue.config.productionTip = false
sync(store, router)
axios.interceptors.request.use(function(config) {
  const token = store.getters['user/token'];
  
  if(token) {
      config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function(err) {
  console.log(err);
  return Promise.reject(err);
});

// Response interceptor for API calls
axios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  
  if (error.response.status === 401) {
    router.replace({ name: "Login" });    
  }
  return Promise.reject(error);
});
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
