<template>
  <v-container id="Login-view" fluid tag="section" fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" md="4" align="center">
        <v-card class="mt-4 text-center " elevation="10">
          <v-avatar
            class="rounded-circle elevation-6 mt-n12 d-inline-block"
            color="white"
            size="85"
          >
            <v-icon size="70" color="primary"> mdi-face-profile </v-icon>
          </v-avatar>

          <v-card-text class="text-center">
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :items="companies"
                      item-text="name"
                      item-value="url"
                      v-model="selectedUrl"
                      label="Company"
                      ref="companies"
                    ></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-inner-icon="mdi-account"
                      v-model="userName"
                      :rules="nameRules"
                      :counter="10"
                      label="User Name"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      prepend-inner-icon="mdi-account-key"
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="nameRules"
                      :type="showPassword ? 'text' : 'password'"
                      name="password"
                      label="Password"
                      counter
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-btn
              class="mr-0"
              color="primary"
              @click="signIn"
              min-width="100"
              :disabled="!valid"
              rounded
            >
              Login
            </v-btn>
            <v-btn
              class="mr-0"
              color="primary"
              @click="loadNewUser"
              min-width="100"
              rounded
              :loading="importLoading"
            >
              New Login
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="showNewLogin">
      <v-card v-if="newUserData" >
        <v-toolbar color="primary" dark>{{newUserData.title}}</v-toolbar>
        <v-card-text class="text-center">
          <div class="text-h5 pa-6 font-weight-bold">{{newUserData.name}}</div><br>
          <div class="text-h6">{{newUserData.contactNo}}</div><br>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="showNewLogin = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" right top color="red">
      {{ messsage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<style>
.theme--light.v-label {
  color: #000080;
}

.theme--light.v-input:not(.v-input--is-disabled) input,
.theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: #000080;
  
}
</style>
<script>
import { sync } from "vuex-pathify";
import configdata from "../assets/config.json";
import axios from "axios";
export default {
  name: "LoginView",
  data: () => ({
    valid: false,
    showPassword: false,
    showNewLogin: false,
    newUserData: null,
    importLoading: false,
    userName: "",
    password: "",
    messsage: "",
    selectedUrl: "",
    snackbar: false,
    nameRules: [(v) => !!v || "Field is required"],
    companies: [],
    
  }),
  computed: {
    isLogin: sync("user/isLogin"),
    apiUrl: sync("app/apiUrl"),
  },
  created() {
    this.readJson('config.json');
  },
  methods: {
    loadNewUser() {
      const self = this;
      
      self.importLoading = true;
      
      let url = self.companies[0].url;
      if (self.selectedUrl != null && self.selectedUrl != "")
        url = self.selectedUrl;
      axios({
        url: url + "api/spNewUserMessage",
        method: "GET",
      })
        .then(function(res) {
          
          self.newUserData = res.data;
          self.showNewLogin = true;
          self.importLoading = false;
        })
        .catch(function(err) {
          self.importLoading = false;
          console.log(err.status);
        });
    },
    readJson(filePath) {
      console.log(filePath);
      const self = this;
      self.companies = configdata.apiUrl;// myFile.apiUrl;
      /*
      axios
        .get(filePath)
        .then(function(response) {
          var myFile = response.data;
          self.companies = configdata;// myFile.apiUrl;
        })
        .catch(function(err) {
          console.log(err);
        });
        */
    },
    signIn() {
      const self = this;
      console.log(self.selectedUrl);
      var data = {
        username: self.userName,
        password: self.password,
      };
      axios({
        url: this.selectedUrl + "api/Users/login",
        data: data,
        method: "POST",
      })
        .then(function(response) {
          self.snackbar = false;
          self.apiUrl = self.selectedUrl;
          let data = Object.assign(
            {},
            response.data,
            { apiUrl: self.selectedUrl },
            { companyName: self.$refs.companies.selectedItems[0].name },
            { logoUrl: self.$refs.companies.selectedItems[0].logoUrl }
          );

          self.$store.dispatch("user/login", data).then(() => {
            self.$router.push({ name: "Dashboard" });
          });
        })
        .catch(function(err) {
          //console.log(err);
          self.messsage = err.Error;

          self.messsage = "Invalid Username or Password";
          self.snackbar = true;
        });
    },
  },
};
</script>
