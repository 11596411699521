<template>
  <v-container id="ChangePassword-view" fluid tag="section" class="px-0 py-0">
    <v-card>
      <v-toolbar color="cyan" dark flat>
        <v-toolbar-title>{{ companyName }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn class="ml-2" min-width="0" text @click="gotoHome">
              <v-icon>mdi-home</v-icon>
            </v-btn>
      </v-toolbar>
      <v-row justify="center" align="center">
        <v-col cols="6">
          <v-card>
            <v-card-text>
              <v-container fluid>
                <v-form v-model="validEdit">
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="oldPassword"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="reqRules"
                        :type="show1 ? 'text' : 'password'"
                        name="oldPassword"
                        label="Old Password"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="newPassword"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="reqRules"
                        :type="show2 ? 'text' : 'password'"
                        name="newPassword"
                        label="New Password"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="confirmPassword"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="rules"
                        :type="show3 ? 'text' : 'password'"
                        name="confirmPassword"
                        label="Confirm Password"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col justify="end" align="end">
                      <v-btn
                        color="blue darken-1"
                        :loading="saveLoading"
                        text
                        @click="saveProfile"
                        :disabled="!validEdit"
                      >
                        Change Password
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { get } from "vuex-pathify";
import { showSnackbar } from "../util/globalActions";
export default {
  name: "ChangePassword",

  data: () => ({
    reqRules: [(v) => !!v || "Field is required"],
    validEdit: false,
    permission: {
      isAdd: false,
      isEdit: false,
      isDelete: false,
      isDownload: false,
    },
    saveLoading: false,
    show1: false,
    show2: false,
    show3: false,
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
    message: "",
    snackbar: false,
  }),
  created() {},
  mounted() {},
  computed: {
    rules() {
      return [
        (v) => !!v || "Field is required",
        (v) =>
          v === this.newPassword ||
          `The new password and confirm password you entered don't match`,
      ];
    },
    apiUrl: get("app/apiUrl"),
    ...get("user", ["isLogin", "fullName", "companyName"]),
  },
  watch: {},
  methods: {
    saveProfile() {
      const self = this;

      self.saveLoading = true;
      axios({
        url: self.apiUrl + "api/Users/updatepassword",
        data: { oldpassword: self.oldPassword, newpassword: self.newPassword },
        method: "POST",
        responseType: "json",
      })
        .then(function(res) {
          self.saveLoading = false;
          showSnackbar({ message: res.data.message });
          if (res.data.status === "OK") {
            self.$store.dispatch("user/logout").then(() => {
              self.$router.replace({ name: "Login" });
            });
          }
        })
        .catch(function(err) {
          self.saveLoading = false;
          self.message = err.status;

          showSnackbar({ message: "Error while update password!" });
        });
    },
    gotoHome(){
      this.$router.replace({ name: "Dashboard" });
    }
  },

  beforeDestroy() {},
};
</script>
