<template>
  <v-container id="ContactUs-view" fluid tag="section" class="px-1">
        <v-row justify="center" align="center">
          <v-col cols="12" md="4">
            <v-card
              elevation="15"
              class="mx-auto px-3 mb-2 pb-3"
               align="center"
               v-if="companyData"
            >
              <v-row class="nblue">
                <v-col cols="12">
                  <h2>{{ companyData.name }}</h2>
                </v-col>
                <v-col cols="12">
                  <span>{{ companyData.address1 }}</span>
                </v-col>
                <v-col cols="12">
                  <span>{{ companyData.address2 }}</span>
                </v-col>
                <v-col cols="12">
                  <span>{{ companyData.address3 }}</span>
                </v-col>
                <v-col cols="12">
                  <v-icon>mdi-phone</v-icon>
                  <span>{{ companyData.telNo }}</span>
                </v-col>
                <v-col cols="12">
                  <v-icon>mdi-email</v-icon>
                  <span>{{ companyData.email }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="12" md="4">
            <v-card
              elevation="15"
              class="mx-auto px-3 mb-2 pb-3"
               align="center"
               v-if="branchData"
            >
              <v-row class="nblue">
                <v-col cols="12">
                  <v-select
                    :items="branches"
                    item-text="name"
                    item-value="branchID"
                    hide-details="auto"
                    v-model="branchId"
                    :loading="loadingBranch"
                    label="Branch"
                    
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <span>{{ branchData.address1 }}</span>
                </v-col>
                <v-col cols="12">
                  <span>{{ branchData.address2 }}</span>
                </v-col>
                <v-col cols="12">
                  <span>{{ branchData.address3 }}</span>
                </v-col>
                <v-col cols="12">
                  <v-icon>mdi-phone</v-icon>
                  <span>{{ branchData.telNo }}</span>
                </v-col>
                <v-col cols="12">
                  <v-icon>mdi-email</v-icon>
                  <span>{{ branchData.email }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
  </v-container>
</template>
<style>
.nblue{
  color: #000080;
}
</style>
<script>
import axios from "axios";
import { get } from "vuex-pathify";
import { showSnackbar } from "../util/globalActions";

export default {
  name: "ContactUsView",
  data: () => ({
    name: "Contact Us",
    lrNo: "",
    lrId: "",
    tab: null,
    dialog: false,
    lrDates: [],
    loadingData: false,
    loadingBranch: false,
    importLoading: false,
    branchId: "",
    companyData: null,
    branches: [],
    branchData: null,
  }),
  created() {
  },
  watch: {
    branchId: function(val) {
      const self = this;
      const branches = self.branches.filter((item) => item.branchID === val);
      self.branchData = branches[0];
    },
  },
  mounted() {
    this.loadCompany();
    this.loadBranches();

  },
  computed: {
    apiUrl: get("user/apiUrl"),
  },

  methods: {
    loadCompany() {
      const self = this;
      self.importLoading = true;
      axios({
        url: self.apiUrl + "api/Company",
        method: "GET",
      })
        .then(function(res) {
          self.companyData = res.data;
          self.importLoading = false;
        })
        .catch(function(err) {
          self.importLoading = false;
          console.log(err.status);
        });
    },
    loadBranches() {
      const self = this;
      self.loadingBranch = true;
      axios({
        url: self.apiUrl + "api/Branches/getbranches",
        method: "GET",
      })
        .then(function(res) {
          self.branches = res.data;
          if (self.branches.length > 0) {
            self.branchId = self.branches[0].branchID;
          }
          self.loadingBranch = false;
        })
        .catch(function(err) {
          self.loadingBranch = false;
          showSnackbar({ message: "Branches Data not found!" });
          console.log(err.status);
        });
    },
  },
};
</script>
