<template>
  <v-container id="ContactUs-view" fluid tag="section" class="px-1">
        <v-row justify="center" align="center">
          <v-col cols="12" md="4">
            <v-card
              elevation="15"
              class="mx-auto px-3 mb-2 pb-3"
               align="center"
               v-if="multomodalData"
            >
              <v-row class="nblue"> 
                <v-col cols="12">
                  <h2>{{ multomodalData.name }}</h2>
                </v-col>
                <v-col cols="12">
                  <span>{{ multomodalData.contact }}</span>
                </v-col>
                <v-col cols="12">
                  <v-icon>mdi-email</v-icon>
                  <span>{{ multomodalData.email }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
  </v-container>
</template>
<style>
.nblue{
  color: #000080;
}
</style>
<script>
import axios from "axios";
import { get } from "vuex-pathify";

export default {
  name: "ContactUsView",
  data: () => ({
    name: "Contact Us",
    tab: null,
    dialog: false,
    loadingData: false,
    branchId: "",
    multomodalData: null,
  }),
  created() {
    
    
  },
  mounted() {
    this.loadMultiModal();
  },
  watch: {
  },

  computed: {
    apiUrl: get("user/apiUrl"),
  },

  methods: {
    loadMultiModal() {
      const self = this;
      self.importLoading = true;
      axios({
        url: self.apiUrl + "api/spMultiModal",
        method: "GET",
      })
        .then(function(res) {
          
          self.multomodalData = res.data;
          self.importLoading = false;
        })
        .catch(function(err) {
          self.importLoading = false;
          console.log(err.status);
        });
    },
  },
};
</script>
