// Pathify
import { make } from 'vuex-pathify'
const env = process.env.NODE_ENV || 'development';
// Data
const state = {
  apiUrl : (env === 'development' ? "http://localhost:51871/" : "http://10.6.33.19:52965/"),
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
